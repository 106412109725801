const offers = [
  {
    "srNo": "1",
    "colSpan": 5,
    "offerPeriod": "4th and 11th April 2024",
    "productType": "Sugar",
    "location": "Chennai, Delhi Gurugram",
    "mov": "3,000",
    "discount": "30",
    "noOfCoupons": "Unlimited",
    "code": "GCLSM30",
    "details": "Rs.30 off on orders worth Rs. 3,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "5,000",
    "discount": "50",
    "noOfCoupons": "Unlimited",
    "code": "GCLSM50",
    "details": "Rs.50 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "10,000",
    "discount": "110",
    "noOfCoupons": "Unlimited",
    "code": "GCLSM110",
    "details": "Rs.110 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "20,000",
    "discount": "240",
    "noOfCoupons": "Unlimited",
    "code": "GCLSM240",
    "details": "Rs.240 off on orders worth Rs. 20,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "30,000",
    "discount": "390",
    "noOfCoupons": "Unlimited",
    "code": "GCLSM390",
    "details": "Rs.390 off on orders worth Rs. 30,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "2",
    "colSpan": 5,
    "offerPeriod": "2, 4, 6, 9 11 and 13 April 2024",
    "productType": "Rice ",
    "location": "Chennai, Delhi Gurugram ",
    "mov": "3,000",
    "discount": "24",
    "noOfCoupons": "Unlimited",
    "code": "GCLSRL24",
    "details": "Rs.24 off on orders worth Rs. 3,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "5,000",
    "discount": "45",
    "noOfCoupons": "Unlimited",
    "code": "GCLSRL45",
    "details": "Rs.45 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "10,000",
    "discount": "100",
    "noOfCoupons": "Unlimited",
    "code": "GCLSRL100",
    "details": "Rs.100 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "15,000",
    "discount": "195",
    "noOfCoupons": "Unlimited",
    "code": "GCLSRL195",
    "details": "Rs.195 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "25,000",
    "discount": "375",
    "noOfCoupons": "Unlimited",
    "code": "GCLSRL375",
    "details": "Rs.375 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "3",
    "colSpan": 5,
    "offerPeriod": "1, 3, 5, 8, 10, 12 April 2024",
    "productType": "Rice ",
    "location": "Chennai, Delhi Gurugram ",
    "mov": "3,000",
    "discount": "51",
    "noOfCoupons": "Unlimited",
    "code": "GCLSRM51",
    "details": "Rs.51 off on orders worth Rs. 3,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "5,000",
    "discount": "87.5",
    "noOfCoupons": "Unlimited",
    "code": "GCLSRM87",
    "details": "Rs.87.5 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "10,000",
    "discount": "185",
    "noOfCoupons": "Unlimited",
    "code": "GCLSRM185",
    "details": "Rs.185 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "15,000",
    "discount": "293",
    "noOfCoupons": "Unlimited",
    "code": "GCLSRM293",
    "details": "Rs.293 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "25,000",
    "discount": "500",
    "noOfCoupons": "Unlimited",
    "code": "GCLSRM500",
    "details": "Rs.500 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "4",
    "colSpan": 5,
    "offerPeriod": "2, 4, 6, 9, 11, 13 April 2024",
    "productType": "FMCG and HoReCa Products /Grocery (i.e. FMCG food, Non Food, Pulses, Spices, Dry Fruits)",
    "location": "Chennai, Delhi, Gurgaon",
    "mov": "3,000",
    "discount": "42",
    "noOfCoupons": "Unlimited",
    "code": "GCLSML42",
    "details": "Rs.42 off on orders worth Rs. 3,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "5,000",
    "discount": "75",
    "noOfCoupons": "Unlimited",
    "code": "GCLSML75",
    "details": "Rs.75 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "10,000",
    "discount": "170",
    "noOfCoupons": "Unlimited",
    "code": "GCLSML170",
    "details": "Rs.170 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "15,000",
    "discount": "300",
    "noOfCoupons": "Unlimited",
    "code": "GCLSML300",
    "details": "Rs.300 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "25,000",
    "discount": "575",
    "noOfCoupons": "Unlimited",
    "code": "GCLSML575",
    "details": "Rs.575 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "5",
    "colSpan": 5,
    "offerPeriod": "1, 3, 5, 8, 10, 12 April 2024",
    "productType": "FMCG and HoReCa Products /Grocery (i.e. FMCG food, Non Food, Pulses, Spices, Dry Fruits)",
    "location": "Chennai, Delhi, Gurgaon",
    "mov": "3,000",
    "discount": "51",
    "noOfCoupons": "Unlimited",
    "code": "GCLSMM51",
    "details": "Rs.51 off on orders worth Rs. 3,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "5,000",
    "discount": "90",
    "noOfCoupons": "Unlimited",
    "code": "GCLSMM90",
    "details": "Rs.90 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "10,000",
    "discount": "190",
    "noOfCoupons": "Unlimited",
    "code": "GCLSAM190",
    "details": "Rs.190 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "15,000",
    "discount": "330",
    "noOfCoupons": "Unlimited",
    "code": "GCLSAM330",
    "details": "Rs.330 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "25,000",
    "discount": "625",
    "noOfCoupons": "Unlimited",
    "code": "GCLSAM625",
    "details": "Rs.625 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "6",
    "colSpan": 5,
    "offerPeriod": "2, 9 and 13 April 2024",
    "productType": "Oil / Oil Products ",
    "location": "Chennai, Delhi, Gurgaon",
    "mov": "3,000",
    "discount": "30",
    "noOfCoupons": "Unlimited",
    "code": "GCLSOM30",
    "details": "Rs.30 off on orders worth Rs. 3,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "5,000",
    "discount": "50",
    "noOfCoupons": "Unlimited",
    "code": "GCLSOM50",
    "details": "Rs.50 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "10,000",
    "discount": "110",
    "noOfCoupons": "Unlimited",
    "code": "GCLSOM110",
    "details": "Rs.110 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "20,000",
    "discount": "240",
    "noOfCoupons": "Unlimited",
    "code": "GCLSOM240",
    "details": "Rs.240 off on orders worth Rs. 20,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "30,000",
    "discount": "390",
    "noOfCoupons": "Unlimited",
    "code": "GCLSOM390",
    "details": "Rs.390 off on orders worth Rs. 30,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "7",
    "colSpan": 5,
    "offerPeriod": "1, 3, 5, 8, 10, 12 April 2024",
    "productType": "Atta",
    "location": "Chennai, Delhi, Gurgaon",
    "mov": "3,000",
    "discount": "30",
    "noOfCoupons": "Unlimited",
    "code": "GCLSAM30",
    "details": "Rs.30 off on orders worth Rs. 3,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "5,000",
    "discount": "55",
    "noOfCoupons": "Unlimited",
    "code": "GCLSAM55",
    "details": "Rs.55 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "10,000",
    "discount": "120",
    "noOfCoupons": "Unlimited",
    "code": "GCLSAM120",
    "details": "Rs.120 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "15,000",
    "discount": "202",
    "noOfCoupons": "Unlimited",
    "code": "GCLSAM202",
    "details": "Rs.202 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "25,000",
    "discount": "375",
    "noOfCoupons": "Unlimited",
    "code": "GCLSAM375",
    "details": "Rs.375 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "8",
    "colSpan": 3,
    "offerPeriod": "1st to 6th, 8th to 13th April 2024",
    "productType": "Grocery (General Merchandise)",
    "location": "All",
    "mov": "1,000",
    "discount": "20",
    "noOfCoupons": "Unlimited",
    "code": "GM20",
    "details": "Rs.20 off on orders worth Rs. 1,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "2,000",
    "discount": "45",
    "noOfCoupons": "Unlimited",
    "code": "GM45",
    "details": "Rs.45 off on orders worth Rs. 2,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "3,000",
    "discount": "75",
    "noOfCoupons": "Unlimited",
    "code": "GM75",
    "details": "Rs.75 off on orders worth Rs. 3,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "9",
    "colSpan": 3,
    "offerPeriod": "1st to 6th, 8th to 13th April 2024",
    "productType": "Sugar",
    "location": "All",
    "mov": "1,000",
    "discount": "20",
    "noOfCoupons": "Unlimited",
    "code": "GSF20",
    "details": "Rs.20 off on orders worth Rs. 1,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "2,000",
    "discount": "45",
    "noOfCoupons": "Unlimited",
    "code": "GSF45",
    "details": "Rs.45 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "3,000",
    "discount": "75",
    "noOfCoupons": "Unlimited",
    "code": "GSF75",
    "details": "Rs.120 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "10",
    "colSpan": 5,
    "offerPeriod": "4th and 11th April 2024",
    "productType": "Sugar",
    "location": "Bangalore and Hyderabad",
    "mov": "3,000",
    "discount": "30",
    "noOfCoupons": "Unlimited",
    "code": "GCLSBHSL30",
    "details": "Rs.30 off on orders worth Rs. 3,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "5,000",
    "discount": "50",
    "noOfCoupons": "Unlimited",
    "code": "GCLSBHSL30",
    "details": "Rs.50 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "10,000",
    "discount": "110",
    "noOfCoupons": "Unlimited",
    "code": "GCLSBHSL110",
    "details": "Rs.110 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "20,000",
    "discount": "260",
    "noOfCoupons": "Unlimited",
    "code": "GCLSBHSL260",
    "details": "Rs.260 off on orders worth Rs. 20,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "30,000",
    "discount": "390",
    "noOfCoupons": "Unlimited",
    "code": "GCLSBHSL390",
    "details": "Rs.390 off on orders worth Rs. 30,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "11",
    "colSpan": 6,
    "offerPeriod": "2, 4, 6, 9 11 and 13 April 2024",
    "productType": "Rice ",
    "location": "Bangalore and Hyderabad",
    "mov": "3,000",
    "discount": "24",
    "noOfCoupons": "Unlimited",
    "code": "GDSR24",
    "details": "Rs.24 off on orders worth Rs. 3,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "5,000",
    "discount": "45",
    "noOfCoupons": "Unlimited",
    "code": "GDSR45",
    "details": "Rs.45 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "10,000",
    "discount": "100",
    "noOfCoupons": "Unlimited",
    "code": "GDSR100",
    "details": "Rs.100 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "15,000",
    "discount": "195",
    "noOfCoupons": "Unlimited",
    "code": "GDSR195",
    "details": "Rs.195 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "25,000",
    "discount": "375",
    "noOfCoupons": "Unlimited",
    "code": "GDSR375",
    "details": "Rs.375 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "35,000",
    "discount": "560",
    "noOfCoupons": "Unlimited",
    "code": "GDSR560",
    "details": "Rs.560 off on orders worth Rs. 35,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "12",
    "colSpan": 6,
    "offerPeriod": "1, 3, 5, 8, 10, 12 April 2024",
    "productType": "Rice ",
    "location": "Bangalore and Hyderabad",
    "mov": "3,000",
    "discount": "48",
    "noOfCoupons": "Unlimited",
    "code": "GDSRM48",
    "details": "Rs.48 off on orders worth Rs. 3,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "5,000",
    "discount": "85",
    "noOfCoupons": "Unlimited",
    "code": "GDSRM85",
    "details": "Rs.85 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "10,000",
    "discount": "175",
    "noOfCoupons": "Unlimited",
    "code": "GDSRM175",
    "details": "Rs.175 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "15,000",
    "discount": "278",
    "noOfCoupons": "Unlimited",
    "code": "GDSRM278",
    "details": "Rs.278 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "25,000",
    "discount": "488",
    "noOfCoupons": "Unlimited",
    "code": "GDSRM488",
    "details": "Rs.488 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "35,000",
    "discount": "700",
    "noOfCoupons": "Unlimited",
    "code": "GDSRM700",
    "details": "Rs.700 off on orders worth Rs. 35,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "13",
    "colSpan": 5,
    "offerPeriod": "2, 4, 6, 9, 11, 13 April 2024",
    "productType": "FMCG and HoReCa Products /Grocery (i.e. FMCG food, Non Food, Pulses, Spices, Dry Fruits)",
    "location": "Bangalore and Hyderabad",
    "mov": "3,000",
    "discount": "42",
    "noOfCoupons": "Unlimited",
    "code": "GFMCG42",
    "details": "Rs.42 off on orders worth Rs. 3,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "5,000",
    "discount": "75",
    "noOfCoupons": "Unlimited",
    "code": "GFMCG75",
    "details": "Rs.75 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "10,000",
    "discount": "170",
    "noOfCoupons": "Unlimited",
    "code": "GFMCG170",
    "details": "Rs.170 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "15,000",
    "discount": "300",
    "noOfCoupons": "Unlimited",
    "code": "GFMCG300",
    "details": "Rs.300 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "25,000",
    "discount": "575",
    "noOfCoupons": "Unlimited",
    "code": "GFMCG575",
    "details": "Rs.575 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "14",
    "colSpan": 5,
    "offerPeriod": "1, 3, 5, 8, 10, 12 April 2024",
    "productType": "FMCG and HoReCa Products /Grocery (i.e. FMCG food, Non Food, Pulses, Spices, Dry Fruits)",
    "location": "Bangalore and Hyderabad",
    "mov": "3,000",
    "discount": "51",
    "noOfCoupons": "Unlimited",
    "code": "GFMCG51",
    "details": "Rs.51 off on orders worth Rs. 3,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "5,000",
    "discount": "90",
    "noOfCoupons": "Unlimited",
    "code": "GFMCG90",
    "details": "Rs.90 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "10,000",
    "discount": "190",
    "noOfCoupons": "Unlimited",
    "code": "GFMCG190",
    "details": "Rs.190 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "15,000",
    "discount": "330",
    "noOfCoupons": "Unlimited",
    "code": "GFMCG330",
    "details": "Rs.330 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "25,000",
    "discount": "625",
    "noOfCoupons": "Unlimited",
    "code": "GFMCG625",
    "details": "Rs.625 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "15",
    "colSpan": 5,
    "offerPeriod": "2, 4, 5, 7, 8, 9, 11, 13, 14 April 2024",
    "productType": "Oil / Oil Products",
    "location": "Bangalore and Hyderabad",
    "mov": "3,000",
    "discount": "21",
    "noOfCoupons": "Unlimited",
    "code": "GCLBHSOL21",
    "details": "Rs.21 off on orders worth Rs. 3,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "5,000",
    "discount": "35",
    "noOfCoupons": "Unlimited",
    "code": "GCLBHSOL35",
    "details": "Rs.35 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "12,000",
    "discount": "84",
    "noOfCoupons": "Unlimited",
    "code": "GCLBHSOL84",
    "details": "Rs.84 off on orders worth Rs. 12,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "20,000",
    "discount": "140",
    "noOfCoupons": "Unlimited",
    "code": "GCLBHSOL140",
    "details": "Rs.140 off on orders worth Rs. 20,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "35,000",
    "discount": "245",
    "noOfCoupons": "Unlimited",
    "code": "GCLBHSOL245",
    "details": "Rs.245 off on orders worth Rs. 35,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "16",
    "colSpan": 5,
    "offerPeriod": "2, 9, 13 April 2024",
    "productType": "Oil / Oil Products",
    "location": "Bangalore and Hyderabad",
    "mov": "3,000",
    "discount": "30",
    "noOfCoupons": "Unlimited",
    "code": "GCLBHSOM30",
    "details": "Rs.30 off on orders worth Rs. 3,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "5,000",
    "discount": "50",
    "noOfCoupons": "Unlimited",
    "code": "GCLBHSOM50",
    "details": "Rs.50 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "10,000",
    "discount": "110",
    "noOfCoupons": "Unlimited",
    "code": "GCLBHSOM110",
    "details": "Rs.84 off on orders worth Rs. 12,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "20,000",
    "discount": "260",
    "noOfCoupons": "Unlimited",
    "code": "GCLBHSOM260",
    "details": "Rs.260 off on orders worth Rs. 20,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "30,000",
    "discount": "390",
    "noOfCoupons": "Unlimited",
    "code": "GCLBHSOM390",
    "details": "Rs.390 off on orders worth Rs. 30,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "srNo": "17",
    "colSpan": 5,
    "offerPeriod": "1, 3, 5, 8, 10, 12 April 2024",
    "productType": "Atta",
    "location": "Bangalore and Hyderabad",
    "mov": "3,000",
    "discount": "30",
    "noOfCoupons": "Unlimited",
    "code": "GCDABH30",
    "details": "Rs.30 off on orders worth Rs. 3,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "5,000",
    "discount": "55",
    "noOfCoupons": "Unlimited",
    "code": "GCDABH55",
    "details": "Rs.55 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "10,000",
    "discount": "120",
    "noOfCoupons": "Unlimited",
    "code": "GCDABH120",
    "details": "Rs.120 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "15,000",
    "discount": "202",
    "noOfCoupons": "Unlimited",
    "code": "GCDABH202",
    "details": "Rs.202 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "25,000",
    "discount": "375",
    "noOfCoupons": "Unlimited",
    "code": "GCDABH375",
    "details": "Rs.375 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  }
]


export default offers;